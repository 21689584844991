import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//import { BotDetectCaptchaModule } from 'angular-captcha';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";
import { EncrDecrService } from './_helpers/encr-decr.service';
import { CKEditorModule } from "ckeditor4-angular";
import {HashLocationStrategy,LocationStrategy} from '@angular/common'

import {LayoutModule} from './pages/layout.module';

// Highlight JS
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { SplashScreenModule } from './_metronic/partials/layout/splash-screen/splash-screen.module';
// #fake-start#
import { DateformatPipe } from './_custom/pipes/dateformat.pipe';
import { NgxSpinnerComponent } from './_helpers/layout/ngx-spinner/ngx-spinner.component';
import { EncryptdecryptPipe } from './_custom/pipes/encryptdecrypt.pipe';
import { EngineerComponent } from './modules/engineer/engineer.component';
import { OnBoardingComponent } from './modules/on-boarding/on-boarding.component';
import { MsalModule, MsalService, MSAL_INSTANCE, MsalInterceptorConfiguration, MsalInterceptor, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: 'a193fc57-049c-4f50-9ea5-eeaf9f61bd05',
      redirectUri: 'https://stage-nde-portal.cb-es.comcast.com/',
      authority : 'https://login.microsoftonline.com/906aefe9-76a7-4f65-b82d-5ec20775d5aa'
    }
  });
}
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}
@NgModule({
  declarations: [AppComponent, DateformatPipe, NgxSpinnerComponent, EncryptdecryptPipe, EngineerComponent, OnBoardingComponent,  
  ],
  imports: [
    MsalModule,
    CKEditorModule,
    LayoutModule,
    BrowserModule,
    BrowserAnimationsModule,
    SplashScreenModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    HighlightModule,
    ClipboardModule,
    AppRoutingModule,
      ToastrModule.forRoot({
        timeOut: 4000,
        positionClass: 'toast-bottom-right',
      }), // ToastrModule added
      InlineSVGModule.forRoot(),
    NgbModule,
    RecaptchaModule,  //this is the recaptcha main module
    RecaptchaFormsModule, //this is the module for form incase form validation
    NgxSpinnerModule,
  ], 
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
          json: () => import('highlight.js/lib/languages/json')
        },
      },
    },
    
    MsalService,
    EncrDecrService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
